import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import {Button, Container, Paper, TextField, ThemeProvider, Typography} from '@mui/material';
import logo from '../img/emblem_logo.png'
import {createTheme} from "@mui/material/styles";

async function loginUser(credentials) {
    try {
        const response = await fetch(process.env.REACT_APP_CL_SERVICES_BASE_URL + 'login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials)
        });
        if (!response.ok) {
            throw new Error('Login failed. Please try again.'); // Throw an error if the response is not ok
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Login error:', error);
        throw error; // Re-throw the error to be caught in the handleSubmit function
    }
}


export default function Login({setAccessToken}) {

    const theme = createTheme({
        components: {
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white',
                        }
                    }
                }
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        '&.Mui-focused': {
                            color: 'white',
                        }
                    }
                }
            }
        },
    });
    const navigate = useNavigate(); // Add this line to use the navigate function
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [error, setError] = useState(false);
    const handleSubmit = async e => {
        e.preventDefault();
        try {
            const token = await loginUser({
                username: username,
                password: password
            });
            if (token && token.accessToken) {
                setAccessToken(token.accessToken);
                navigate('/');
            }
        } catch (error) {
            setError(true); // Set error to true to show the error message
            setTimeout(() => setError(false), 5000); // Remove the error message after 5 seconds
        }
    }


    return (
        <Container component="main" maxWidth="xs">
            <ThemeProvider theme={theme}>
                <Paper elevation={6}
                       style={{
                           marginTop: '50%',
                           padding: '20px',
                           backgroundColor: '#3c3c3c',
                           border: '1px solid gray',
                           animation: error ? 'shake 0.5s' : 'none'
                       }}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <img src={logo} alt={'logo'} style={{width: '15%', marginRight: '8px'}}/>
                        <Typography variant='h5' style={{color: 'whitesmoke'}}>
                            Dashboard
                        </Typography>
                    </div>
                    <form onSubmit={handleSubmit} style={{marginTop: '25px'}}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            autoComplete="username"
                            autoFocus
                            onChange={e => setUserName(e.target.value)}
                            InputProps={{
                                style: {color: 'whitesmoke', borderColor: 'whitesmoke'},
                                notchedOutline: {
                                    borderColor: 'whitesmoke',
                                },
                            }}
                            InputLabelProps={{
                                style: {color: 'whitesmoke'},
                            }}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={e => setPassword(e.target.value)}
                            InputProps={{
                                style: {color: 'whitesmoke'}
                            }}
                            InputLabelProps={{
                                style: {color: 'whitesmoke'},
                            }}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            style={{margin: '10px 0px 10px', backgroundImage: 'linear-gradient(#4498e8, #12d6ec)'}}
                            size="large"
                        >
                            Anmelden
                        </Button>
                        {error && (
                            <Typography color="error" style={{textAlign: 'center', margin: '10px 0'}}>
                                Login failed. Please try again.
                            </Typography>
                        )}
                    </form>
                </Paper>
            </ThemeProvider>
        </Container>
    )
}

Login.propTypes = {
    setAccessToken: PropTypes.func.isRequired
};
