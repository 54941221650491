import React, {useEffect, useRef} from 'react';
import {Chart} from 'chart.js/auto';

const CLGraph = ({data, x, y, maxRatio = 10, title, unit = ''}) => {
    const chartRef = useRef();
    const animationDuration = 1500;
    const chartInstance = useRef(null);

    useEffect(() => {
        const ctx = chartRef.current?.getContext('2d');

        if (!ctx) {
            console.error('Canvas context is null or undefined.');
            return;
        }


        if (chartInstance.current === null && data) {
            const initialData = data.map(entry => parseFloat(entry[y]));
            const labels = data.map(entry => entry[x]);

            chartInstance.current = new Chart(ctx, {
                type: 'line', data: {
                    labels: labels,
                    datasets: [{
                        legend: false,
                        data: [...initialData],
                        borderColor: 'rgba(75, 192, 192, 1)',
                        borderWidth: 3,
                        fill: true,
                        pointRadius: 0.1,
                        pointHoverRadius: 10,
                        pointHitRadius: 10,
                    },],
                }, options: {
                    animation: {
                        duration: animationDuration,
                    }, plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            callbacks: {
                                label: function (tooltipItem, data) {
                                    return ' ' + tooltipItem.formattedValue + ' ' + unit;
                                }
                            }
                        }

                    }, scales: {
                        x: {
                            ticks: {
                                autoSkip: true,
                                maxRotation: 25,
                                minRotation: 25,
                                callback: function (value, index) {
                                    const actualValue = labels[index];
                                    return actualValue.split(',')[0];
                                }
                            },
                        }, y: {
                            max: Math.max(...initialData) + maxRatio,
                            ticks: {
                                callback: function (value, index) {
                                    return value + ' ' + unit
                                }
                            }
                        },
                    },
                },
            });

            const animateChart = () => {
                let currentIndex = 0;
                const animationInterval = setInterval(() => {
                    const newDataPoint = data[currentIndex];
                    chartInstance.current.data.datasets[0].data.push(newDataPoint[x]);
                    chartInstance.current.update();
                    currentIndex++;

                    if (currentIndex === data.length) {
                        clearInterval(animationInterval);
                    }
                }, 100);

                animateChart();
            }
        }

        return () => {
            if (chartInstance.current !== null) {
                chartInstance.current.destroy();
                chartInstance.current = null;
            }
        };
    }, [data, x, y, animationDuration, title]);

    return <canvas ref={chartRef} style={{padding: '20px'}}></canvas>;
};

export default CLGraph;
