import {createTheme} from '@mui/material/styles';

export const darkTheme = createTheme({
    palette: {
        primary: {
            main: '#333333', // Sets the primary color to black
        },
        background: {
            main: '#333333'
        }
    },
});

export const lightTheme = createTheme({
    palette: {
        primary: {
            main: '#fff', // Sets the primary color to black
        },
    },
});


