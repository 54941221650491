import * as React from 'react';
import {useState} from 'react';
import {api} from "../../dashboards/CL_api";
import {
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    LinearProgress,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Slider,
    TextField,
    Typography
} from "@mui/material";


import {ErrorRounded, HardwareRounded} from "@mui/icons-material";
import FormLabel from "@mui/material/FormLabel";

import MiningCalculatorTable from "../../dashboards/components/miningCalculatorTable";


export default function Mining() {

    const headerMining = {
        fontSize: '20px',
        fontWeight: 'bold'
    };

    const [amount, setAmount] = useState()
    const [unit, setUnit] = useState('kw');
    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState()
    const [results, setResults] = useState()
    const [isSolar, setIsSolar] = useState(1)
    const [kWp, setKWp] = useState()
    const [usageRatio, setUsageRation] = useState(100)
    const [cooling, setCooling] = useState('water')
    const [reductionFactor, setReductionFactor] = useState(15)

    const handleReductionFactor = (event) => {
        const reduction = event.target.value;
        setReductionFactor(reduction);
    }

    const handleCooling = (event) => {
        const cooling = event.target.value;
        setCooling(cooling);
    }

    const handleUsageRatio = (event) => {
        const usageR = event.target.value;
        setUsageRation(usageR);
    };
    const handleAmount = (event) => {
        const amount = event.target.value;
        setAmount(amount);
    };

    const handleUnitChange = (event) => {
        const selectedUnit = event.target.value;
        setUnit(selectedUnit);
    };

    const handlekWp = (event) => {
        const kwp = event.target.value
        setKWp(kwp)
    }

    function calculateEfficiency() {
        let kw = 0
        let unitString = 'kw'
        let reduction = reductionFactor / 100
        if (isSolar == 1) {
            if (kWp === '' || kWp == null) {
                setErrorMessage('Bitte geben Sie die zugrundeliegenden kWp ein.')
                return
            }
            kw = parseFloat(kWp) * (parseInt(usageRatio) / 100)
        } else {
            if (amount === '' || amount == null) {
                setErrorMessage('Bitte geben Sie die kW ein.');
                return;
            }
            kw = parseFloat(amount)
            unitString = unit
            reduction = 0
        }
        setErrorMessage('');
        setIsLoading(true);
        setResults(null)

        const params = {
            [unitString]: kw,
            'cooling': cooling,
            ...(reduction > 0 && {'reduction_factor': reduction})
        }
        api.get('/get_mining_profit', {params})
            .then(response => {
                setResults(response.data);
            })
            .catch(error => {
                const message = error.response.data.error ?? 'Internal Server Error'
                setErrorMessage(message)
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleChangeIsSolar = (event) => {
        setIsSolar(event.target.value)
    }

    return (
        <>
            <Grid container spacing={2} className='clContainer'>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item>
                        <IconButton className='clButton'>
                            <HardwareRounded style={{color: 'white', fontWeight: 'bold'}}/>
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <h1 style={{margin: '10px'}}>Mining Calculator</h1>
                    </Grid>
                    <Grid item xs={12}>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Typography style={headerMining}>Cooling</Typography>
                                </Grid>
                                <Grid item xs={2}
                                      style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <FormControl
                                        variant="outlined"
                                        fullwidth
                                        sx={{
                                            width: '100%',
                                            color: 'white',
                                            '& .MuiSelect-icon': {color: 'white'}
                                        }}
                                    >
                                        <Select
                                            required
                                            value={cooling}
                                            variant='outlined'
                                            onChange={handleCooling}
                                            style={{color: 'white', background: 'transparent'}}
                                            sx={{
                                                borderColor: 'white',
                                                width: '100%',
                                                textAlign: 'left'
                                            }}
                                        >
                                            <MenuItem key={1} value={'water'}>
                                                Water
                                            </MenuItem>
                                            <MenuItem key={2} value={'air'}>
                                                Air
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={5}>
                            <Typography style={headerMining}>Solar Energy</Typography>
                        </Grid>
                        <Grid item xs={3}
                              style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <FormControl>
                                <RadioGroup
                                    value={isSolar} onChange={handleChangeIsSolar} row>
                                    <FormControlLabel value={1} control={<Radio/>} label="Ja"/>
                                    <FormControlLabel value={0} control={<Radio/>} label="Nein"/>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        {isSolar == 1 ? (
                            <>
                                <Grid item xs={5}>
                                    <Typography style={headerMining}>Performance</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label="Höchstleistung in Kilowatt (kWp)"
                                        variant="outlined"
                                        value={kWp}
                                        type={"number"}
                                        onChange={handlekWp}
                                        InputProps={{
                                            style: {color: 'white', backgroundColor: 'transparent'}, // Add backgroundColor: 'transparent'
                                        }}
                                        InputLabelProps={{
                                            style: {color: 'white'}, // Apply white color to input label
                                            shrink: true, // Allow the label to shrink when there's input
                                            component: FormLabel, // Use FormLabel for the label component
                                            borderColor: 'white',
                                        }}
                                        InputLabelComponent="label" // Set the label component to "label"
                                        style={{
                                            color: 'white',
                                            width: '100%'
                                        }} // Apply white text color
                                    />
                                </Grid>
                                <Grid item xs={4} sm={4}></Grid>
                            </>
                        ) : (
                            <>
                                <Grid item xs={4} sm={4}>
                                    <TextField
                                        label="Menge"
                                        value={amount}
                                        type={"number"}
                                        onChange={handleAmount}
                                        InputProps={{
                                            style: {color: 'white', backgroundColor: 'transparent'}, // Add backgroundColor: 'transparent'
                                        }}
                                        InputLabelProps={{
                                            style: {color: 'white'}, // Apply white color to input label
                                            shrink: true, // Allow the label to shrink when there's input
                                            component: FormLabel, // Use FormLabel for the label component
                                        }}
                                        InputLabelComponent="label" // Set the label component to "label"
                                        style={{
                                            color: 'white',
                                            width: '100%'
                                        }} // Apply white text color
                                    />
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                    <FormControl
                                        fullwidth
                                        sx={{
                                            width: '100%',
                                            color: 'white',
                                            '& .MuiSelect-icon': {color: 'white'}
                                        }}
                                    >
                                        <InputLabel style={{color: 'white'}}>Einheit</InputLabel>
                                        <Select
                                            required
                                            value={unit}
                                            onChange={handleUnitChange}
                                            label="Unit"
                                            style={{color: 'white', background: 'transparent'}}
                                            sx={{
                                                width: '100%',
                                                textAlign: 'left' // Set text alignment to left
                                            }}
                                        >
                                            <MenuItem key={1} value={'kw'}>
                                                kW
                                            </MenuItem>
                                            <MenuItem key={2} value={'kwh_day'}>
                                                kW/day
                                            </MenuItem>
                                            <MenuItem key={3} value={'kwh_year'}>
                                                kw/year
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </>
                        )}
                        {isSolar != 0 &&
                            <Grid item xs={8}>

                                <Typography style={headerMining}>
                                    System Utilization
                                </Typography>
                                <Typography
                                    style={{fontSize: '30px', textAlign: 'center'}}> {usageRatio} %</Typography>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12}>
                                        <Slider
                                            value={usageRatio}
                                            onChange={handleUsageRatio}
                                            valueLabelDisplay="auto"
                                            step={5}
                                            marks
                                            min={0}
                                            max={100}
                                        />
                                    </Grid>
                                    <Grid item>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </Grid>

                    {isSolar != 0 &&

                        <Grid container spacing={2}>

                            <Grid item xs={6}>
                                <Typography
                                    style={headerMining}>Reducing factor
                                    in
                                    %</Typography>
                            </Grid>
                            <Grid item xs={2}
                                  style={{display: 'flex', justifyContent: 'flex-end'}}>
                                <TextField
                                    variant="outlined"
                                    value={reductionFactor}
                                    type={"number"}
                                    onChange={handleReductionFactor}
                                    InputProps={{
                                        style: {color: 'white', backgroundColor: 'transparent'}, // Add backgroundColor: 'transparent'
                                    }}
                                    InputLabelProps={{
                                        style: {color: 'white'}, // Apply white color to input label
                                        shrink: true, // Allow the label to shrink when there's input
                                        component: FormLabel, // Use FormLabel for the label component
                                        borderColor: 'white',
                                    }}
                                    InputLabelComponent="label" // Set the label component to "label"
                                    style={{color: 'white', width: '100%'}} // Apply white text color
                                />
                            </Grid>
                        </Grid>
                    }
                    <Grid container spacing={2}>
                        <Grid item xs={8} sm={8} style={{margin: '10px 0px'}}>
                            <Button variant='contained' className='clButton' onClick={calculateEfficiency}
                                    style={{height: '100%', margin: '5px'}}
                                    fullWidth
                                    size="large">
                                Calculate
                            </Button>
                        </Grid>
                    </Grid>

                </Grid>
                {errorMessage && (
                    <Grid item xs={12}>
                        <ErrorRounded style={{color: '#FF3232', fontSize: '35px'}}/>
                        <Typography
                            style={{
                                color: '#FF3232',
                                textAlign: 'center',
                                marginBottom: '20px',
                                fontWeight: 'bold',
                                fontSize: '20px'
                            }}
                            variant='body2'
                            dangerouslySetInnerHTML={{__html: errorMessage}}
                        />
                    </Grid>)}
            </Grid>

            {
                isLoading ? (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '50px'
                    }}>
                        <Grid container space={2} style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <Grid item xs={5}>
                                <LinearProgress variant="determinate" style={{margin: '10px'}}/>
                                <Typography variant="body1" color="white">
                                    Calculating data ...
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                ) : (
                    results && (
                        <>
                            <MiningCalculatorTable data={results}/>
                        </>
                    )
                )
            }
        </>
    )
}
