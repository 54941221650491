import * as React from 'react';
import {Avatar, CardContent, CardHeader, CardMedia, Grid, IconButton, Tooltip, Typography} from "@mui/material";
import Card from "react-bootstrap/Card";
import teaserItems from "../data/teaser";
import {RocketLaunch} from "@mui/icons-material";

export default function Inbox() {


    return (

        <Grid container spacing={2} style={{margin: '10px', justifyContent: 'center', alignItems: 'center'}}>
            {teaserItems.map((item, index) => (

                <Grid item>
                    <a href={item.path} style={{textDecoration: 'none', color: 'white'}}>
                        < Card
                            style={{
                                backgroundColor: 'rgba(255, 255, 255, 0.05)',
                                padding: '10px',
                                borderRadius: '5px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                border: '1px solid #666'
                            }}>

                            <CardHeader
                                avatar={
                                    <Avatar aria-label={item.text}
                                            sx={{width: 40, height: 40, backgroundColor: 'transparent'}}>
                                        {React.createElement(item.icon, {
                                            style: {fontSize: '2rem'}
                                        })}
                                    </Avatar>
                                }
                                title={item.text}
                                titleTypographyProps={{
                                    fontSize: 24,
                                    marginLeft: '-15px',
                                }}
                            />
                            < CardMedia
                                component="img"
                                image={item.imagePath}
                                alt={item.text}
                                sx={{width: '53%'}}

                            />
                            <CardContent>
                                <Typography variant="body2" color="white">
                                    Blockchain smart contract analysis.
                                </Typography>
                            </CardContent>

                            <a href={item.path}>
                                <Tooltip title='Launch'>
                                    <IconButton className={'clButton'}>
                                        <RocketLaunch style={{color: 'white', fontSize: '35px'}}/>
                                    </IconButton>
                                </Tooltip>
                            </a>
                        </Card>
                    </a>
                </Grid>
            ))}
        </Grid>
    );
}
