import axios from 'axios';
import {CL_ACCESS_TOKEN, CL_STANDARD_URL} from '../dotenv'

const defaultDomain = CL_STANDARD_URL;

const createApiInstance = (baseURL) => {
    return axios.create({
        baseURL,
        headers: {
            'Content-Type': 'application/json',
            'x-access-token': CL_ACCESS_TOKEN
        },
        timeout: 60000,
    });
};

const api = createApiInstance(defaultDomain);

export {api, createApiInstance};
