import * as React from 'react';


export default function CoreAPI() {


    return (
        <div>
            <p>Core API - Needs the endpoints</p>
            <p>When will Stefan finally send the endpoints.</p>
        </div>
    );
}
