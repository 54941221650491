import {Navigate, Route, Routes, useNavigate} from 'react-router-dom';
import useAccessToken from "./useToken";
import Login from "./components/Login";
import Dashboard from './components/dashboard';
import {ThemeProvider} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import {darkTheme, lightTheme} from "./theme";
import {ProtectedRoute} from "./components/UIElements";
import Mining from "./components/pages/mining";
import Taxation from "./components/pages/taxation";
import Home from "./components/pages/home";
import SCA from "./components/pages/SCA";
import CoreAPI from "./components/pages/core";

function App() {
    const {accessToken, setAccessToken} = useAccessToken();
    const [selectedTheme, setSelectedTheme] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        if (accessToken === null) {
            navigate('/login');
        }
    }, [accessToken, navigate]);
    return (
        <ThemeProvider theme={selectedTheme ? darkTheme : lightTheme}>
            <Routes>
                <Route path="/login" element={<Login setAccessToken={setAccessToken} accessToken={accessToken}/>}/>
                {/*<Route path="/register"
                       element={<Register setAccessToken={setAccessToken} accessToken={accessToken}/>}/>*/}
                <Route path="/" element={
                    <ProtectedRoute>
                        <Dashboard setAccessToken={setAccessToken}/>
                    </ProtectedRoute>
                }>
                    {/* Nested routes for subpages of Dashboard */}
                    <Route index element={<Home/>}/> {/* Default subpage */}
                    <Route path="home" element={<Home/>}/>
                    <Route path="taxation" element={<Taxation/>}/>
                    <Route path="mining" element={<Mining/>}/>
                    <Route path="SCA" element={<SCA/>}/>
                    <Route path="coreAPI" element={<CoreAPI/>}/>

                </Route>
                {/* Redirect to default subpage if no match found */}
                <Route path="*" element={<Navigate replace to="/home"/>}/>
            </Routes>
        </ThemeProvider>
    );
}

export default App;
