import React, {useEffect, useState} from 'react';
import {Avatar, Box, CircularProgress, Grid, Tab, Tabs, Typography} from "@mui/material";
import PropTypes from "prop-types";
import {CurrencyBitcoinRounded, NetworkCheckRounded, TagRounded} from "@mui/icons-material";
import CLGraph from "./CLGraph";
import mem_api from "../mempool_api";


function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3, color: 'white'}}>
                    <Typography color="inherit">{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}


function formatDate(graphData, timeString, reverse = false) {
    let result = null
    if (reverse) {
        result = graphData.reverse().map(entry => {
            const timestamp = entry[timeString] * 1000; // Assuming the timestamp is in seconds, convert it to milliseconds
            const date = new Date(timestamp);

            // Format the date to German time format (you can adjust the options as needed)
            const formattedDate = date.toLocaleString('de-DE', {
                year: '2-digit',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
            });

            // Add the formatted date to the entry
            return {...entry, formattedTime: formattedDate};
        })
    } else {
        result = graphData.map(entry => {
            const timestamp = entry[timeString] * 1000; // Assuming the timestamp is in seconds, convert it to milliseconds
            const date = new Date(timestamp);

            // Format the date to German time format (you can adjust the options as needed)
            const formattedDate = date.toLocaleString('de-DE', {
                year: '2-digit',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
            });

            // Add the formatted date to the entry
            return {...entry, formattedTime: formattedDate};
        })
    }
    return result;
}


const GraphTab = () => {
    const [value, setValue] = React.useState(0);
    const [BTCPrice, setBTCPrice] = useState()
    const [hashrates, setHashrates] = useState()
    const [difficulty, setDifficulty] = useState()

    useEffect(() => {
        const fetchData = async () => {
            try {
                const btcPrice = await mem_api.get('/historical-price')
                const hash = await mem_api.get('/mining/hashrate/6m')
                const btcPriceArray = btcPrice.data.prices
                let hashes = hash.data.hashrates
                let diffi = hash.data.difficulty

                diffi = diffi.map((hash) => ({
                    ...hash,
                    difficulty: parseInt(hash.difficulty / 1000000000000),
                }));

                hashes = hashes.map((hash) => ({
                    ...hash,
                    avgHashrate: parseInt(hash.avgHashrate / 1000000000000000000),
                }));
                
                const formattedBtcPriceArray = formatDate(btcPriceArray, 'time', true)
                setBTCPrice(formattedBtcPriceArray.slice(-2160))
                const hashArray = formatDate(hashes, 'timestamp')
                const diffArray = formatDate(diffi, 'time')
                setDifficulty(diffArray)
                setHashrates(hashArray)

                //const hash = await mem_api.get('/mining/hashrate/3d')
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData(); // Call the async function immediately
    }, []);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{flexGrow: 1, display: 'flex', height: 400}}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                sx={{borderRight: 2, borderColor: 'divider', color: 'white', minWidth: '250px', margin: '15px'}}
            >
                <Tab label={
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <Avatar style={{backgroundColor: 'orange',}}>
                                <CurrencyBitcoinRounded/>
                            </Avatar>
                        </Grid>
                        <Grid item>
                            BTC Preis
                        </Grid>
                    </Grid>}
                     {...a11yProps(1)}
                     style={{color: 'white', fontSize: '18px'}}/>
                <Tab label={
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <Avatar style={{backgroundColor: 'CornflowerBlue',}}>
                                <NetworkCheckRounded/>
                            </Avatar>
                        </Grid>
                        <Grid item>
                            Difficulty
                        </Grid>
                    </Grid>}
                     {...a11yProps(0)}
                     style={{color: 'white', fontSize: '18px'}}/>
                <Tab label={
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <Avatar style={{backgroundColor: 'DarkKhaki',}}>
                                <TagRounded/>
                            </Avatar>
                        </Grid>
                        <Grid item>
                            Hashrate
                        </Grid>
                    </Grid>}
                     {...a11yProps(2)}
                     style={{color: 'white', fontSize: '18px'}}/>
            </Tabs>

            <TabPanel value={value} index={0} style={{width: '80%'}}>

                {BTCPrice ? (
                    <CLGraph data={BTCPrice} x='formattedTime' y='EUR' maxRatio={100} title={'Bitcoin Preis'}
                             unit={'€'}/>

                ) : (
                    <div style={{
                        display: 'flex',
                        height: '350px',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <CircularProgress variant="indeterminate"/>
                            </Grid>
                            <Grid item xs={12}>
                                <p> ...Loading Bitcoin Preis Chart.</p>
                            </Grid>
                        </Grid>

                    </div>
                )}
                {/*<CLGraph/>*/}
            </TabPanel>
            <TabPanel value={value} index={1} style={{width: '80%'}}>
                {difficulty ? (
                    <CLGraph data={difficulty} x='formattedTime' y='difficulty' maxRatio={20} title={'Difficulty'}
                             unit={'T'}/>
                ) : (
                    <div style={{
                        display: 'flex',
                        height: '350px',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <CircularProgress variant="indeterminate"/>
                            </Grid>
                            <Grid item xs={12}>
                                <p> ...Loading Difficulty Chart.</p>
                            </Grid>
                        </Grid>

                    </div>
                )}
            </TabPanel>
            <TabPanel value={value} index={2} style={{width: '80%'}}>
                {hashrates ? (
                    <CLGraph data={hashrates} x='formattedTime' y='avgHashrate' maxRatio={20} title={'Hashrate'}
                             unit={'EH/s'}/>
                ) : (
                    <div style={{
                        display: 'flex',
                        height: '350px',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <CircularProgress variant="indeterminate"/>
                            </Grid>
                            <Grid item xs={12}>
                                <p> ...Loading Hashrate Chart.</p>
                            </Grid>
                        </Grid>

                    </div>
                )}
            </TabPanel>
        </Box>
    )
};
export default GraphTab;