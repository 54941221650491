import axios from 'axios';

const mem_api = axios.create({
    baseURL: 'https://mempool.space/api/v1',
    headers: {
        'Content-Type': 'application/json',
    },
    timeout: 60000,
});

export default mem_api;
