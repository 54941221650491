import {AccountBalanceRounded, Analytics, DashboardRounded, HardwareRounded, WebhookRounded} from '@mui/icons-material/'
import Home from '../pages/home'
import Taxation from "../pages/taxation";
import Mining from "../pages/mining";
import SCA from '../pages/SCA';
import CoreAPI from '../pages/core'

const menuItems = [
    {text: 'Dashboard', icon: DashboardRounded, color: 'blue', component: Home, path: '/home', default: true},
    {text: 'Analytics', icon: Analytics, color: 'blue', component: SCA, path: '/SCA'},
    {text: 'Taxation', icon: AccountBalanceRounded, color: 'blue', component: Taxation, path: '/taxation'},
    {text: 'Mining', icon: HardwareRounded, color: 'blue', component: Mining, path: '/mining'},
    {text: 'Core API', icon: WebhookRounded, color: 'blue', component: CoreAPI, path: '/coreAPI'}


];

export default menuItems;
