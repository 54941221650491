import React, {useEffect, useState} from 'react';
import {Grid} from "@mui/material";
import Teaser from "./teaser";
import {CurrencyBitcoinRounded, HardwareOutlined, PoolOutlined} from "@mui/icons-material";
import mem_api from '../mempool_api.js'
import GraphTab from "./graphTab";

const MiningCalculatorTable = ({data}) => {
    const [price, setPrice] = useState(90000)

    function parseData(item) {
        if (item.type === 'str') {
            return item.value
        } else if (item.type === 'int') {
            return parseInt(item.value)
        } else if (item.type === 'euro') {
            return parseFloat(item.value.toFixed(2)).toLocaleString('de-DE', {maximumFractionDigits: 2})
        } else if (item.type === 'float') {
            return parseFloat(item.value.toFixed(2)).toLocaleString('de-DE', {maximumFractionDigits: 2})
        } else {
            return item.value
        }
    }

    const sections = Object.values(data).reduce((acc, item) => {
        if (item.section) {
            if (!acc[item.section]) {
                acc[item.section] = [];
            }
            acc[item.section].push(item);
        }
        return acc;
    }, {});

    for (const section in sections) {
        sections[section].sort((a, b) => a.sort_idx - b.sort_idx);
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const prices = await mem_api.get('/prices')
                const btcPrice = await mem_api.get('/historical-price')
                setPrice(prices.data.EUR)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData(); // Call the async function immediately
    }, []);


    return (
        <>

            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '50px'
            }}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Teaser text='Bitcoin Preis' icon={<CurrencyBitcoinRounded/>} color='orange'
                                value={`€ ${parseFloat(price.toFixed(2)).toLocaleString('de-DE', {maximumFractionDigits: 2})}`}
                                info='Current BTC price'/>

                        <Teaser text='Solo Mining' icon={<HardwareOutlined/>} color='green'
                                value={`€ ${data?.farm_income_per_kw_solo?.value.toLocaleString('de-DE', {maximumFractionDigits: 2}) ?? 0} kWh`}
                                info='Vergütung pro kWh (EUR)'/>


                        <Teaser text='Mining Pool' icon={<PoolOutlined/>} color='DeepSkyBlue'
                                value={`€ ${data?.farm_income_per_kw?.value.toLocaleString('de-DE', {maximumFractionDigits: 2}) ?? 0} kWh`}
                                info='Vergütung pro kWh (EUR)'/>

                        {/*<Teaser text='Difficulty' icon={<NetworkCheckRounded/>} color='PaleVioletRed'
                                value={`T ${difficulty.toFixed(2).toLocaleString('de-DE', {maximumFractionDigits: 2})}`}
                                info='Hashrate in EH/s'/>*/}

                        {/*<Teaser text='Top Gainer' icon={<EmojiEvents/>} color='green' value={'GROK'} addition={'300%'}
                                info='Most gaining token in the last 24 hours'/>
                        <Teaser text='Loser' icon={<ThumbDownAltRounded/>} color='purple' value={'QBEE'}
                                info='Most losing token in the last 24 hours'/>*/}
                    </Grid>
                </Grid>
            </div>

            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '50px'}}>
                <Grid container spacing={2} className="cryptolight-container">
                    <GraphTab/>
                </Grid>
            </div>

            {Object.keys(sections).map(section => (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '50px'
                }}>
                    <Grid container spacing={2} className="cryptolight-container">
                        <Grid item xs={12} key={section} stlye={{padding: '200px'}}>
                            <h2>{section}</h2>
                            <table style={{width: '100%'}}>
                                <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {sections[section].map(item => (
                                    <tr key={item.name}>
                                        <td style={{
                                            textAlign: 'left',
                                            fontSize: '20px',
                                            padding: '15px'
                                        }}>{item.name}</td>
                                        <td style={{
                                            textAlign: 'right',
                                            paddingRight: '30px',

                                            fontWeight: 'bold',
                                            fontSize: '22px'
                                        }}>{parseData(item)}

                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </Grid>
                    </Grid>
                </div>
            ))}
            <div style={{margin: '20px'}}></div>
        </>

    )
};
export default MiningCalculatorTable;