import {AccountBalanceRounded, Analytics, HardwareRounded} from '@mui/icons-material/'
import Taxation from "../pages/taxation";
import Mining from "../pages/mining";
import SCA from '../pages/SCA';
import {WebhookRounded} from "@mui/icons-material";
import CoreAPI from "../pages/core";

const teaserItems = [
    {
        text: 'Analytics',
        icon: Analytics,
        imagePath: 'teaser/target.png',
        color: 'blue',
        component: SCA,
        path: '/SCA'
    },
    {
        text: 'Taxation',
        icon: AccountBalanceRounded,
        imagePath: 'teaser/smart_contract.png',
        color: 'blue',
        component: Taxation,
        path: '/taxation'
    },
    {
        text: 'Mining',
        icon: HardwareRounded,
        imagePath: 'teaser/data-mining.png',
        color: 'blue',
        component: Mining,
        path: '/mining'
    },
    {
        text: 'Core API',
        icon: WebhookRounded,
        imagePath: 'teaser/api.png',
        color: 'blue',
        component: CoreAPI,
        path: '/coreAPI'
    }

];

export default teaserItems;
