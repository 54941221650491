import React from 'react';
import {Avatar, Grid, Tooltip, Typography} from '@mui/material';
import {InfoOutlined} from "@mui/icons-material";

export default function Teaser(props) {
    const avatarStyle = {
        backgroundColor: props.color || 'defaultColor', // Use the provided color or a default color
    };

    return (
        <Grid item
              style={{
                  backgroundColor: 'rgba(255, 255, 255, 0.075)',
                  borderRadius: '10px',
                  padding: '10px 30px',
                  margin: '5px'
              }}>
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                    <Avatar style={avatarStyle}>
                        {props.icon}
                    </Avatar>
                </Grid>
                <Grid item>
                    <Typography variant='h5'>
                        {props.text}
                    </Typography>
                </Grid>
                {props.info && <Grid item>
                    <Tooltip title={props.info}>
                        <InfoOutlined style={{fontSize: 16}}/>
                    </Tooltip>
                </Grid>}
            </Grid>
            <Grid item xs={12} style={{marginTop: '10px'}}>
                <Typography variant='h4' color={props.color}>
                    <b>
                        {props.value}
                    </b>
                </Typography>
            </Grid>
        </Grid>
    );
}
