import {IconButton, Tooltip} from "@mui/material";
import useAccessToken from "../useToken";
import {Navigate, useLocation} from "react-router-dom";
import {styled} from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import {LogoutRounded} from "@mui/icons-material";

export const LogoutButton = ({setAccessToken}) => {
    const handleLogout = () => {
        localStorage.removeItem('accessToken');
        setAccessToken(null);
        // This will trigger a re-render and the useEffect below will run
    };

    return (
        <Tooltip title='Logout'>
            <IconButton
                onClick={handleLogout} // Make sure this function is defined in your component
                className='clButton'>
                <LogoutRounded style={{color: 'white', fontWeight: 'bold'}}/>
            </IconButton>
        </Tooltip>
    );
};


export function ProtectedRoute({children}) {
    const {accessToken} = useAccessToken();
    const location = useLocation();
    if (!accessToken || accessToken === 'null') {
        return <Navigate to="/login" state={{from: location}} replace/>;
    }
    return children;
}

export const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));


export const AppBar = styled(MuiAppBar, {

    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: 240,
        width: `calc(100% - ${240}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));
