import * as React from 'react';
import {useEffect, useState} from 'react';
import {createApiInstance} from "../../dashboards/CL_api";
import Web3 from "web3";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    AlertTitle,
    Box,
    Button,
    CardContent,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    LinearProgress,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";

import Card from "react-bootstrap/Card";
import {
    DateRange,
    EqualizerRounded,
    ErrorOutlineOutlined,
    ExpandLess,
    FileCopy,
    MonetizationOn,
    NotInterested,
    SearchOutlined,
    TrendingFlat
} from "@mui/icons-material";


export default function Taxation() {
    const [chainList, setChainList] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const [contractAddress, setContractAddress] = useState('')
    const [errorMessage, setErrorMessage] = useState('');
    const [results, setResults] = useState([])
    const [relevantDate, setRelevantDate] = useState('');
    const [outSymbol, setOutSymbol] = useState('EUR'); // Default value is 'EUR'
    const [isLoading, setIsLoading] = useState(false)
    const [serverResponse, setServerResponse] = useState('')
    const [addressError, setAddressError] = useState('')
    const [progress, setProgress] = useState(0);
    const [txHash, setTxHash] = useState('')
    const [searchExecuted, setSearchExecuted] = useState(false);
    const api = createApiInstance('https://mct-link.de:1322/v1/');

    const isValidAddress = (address) => {
        if (!address) {
            return false;
        }

        const web3 = new Web3();
        return web3.utils.isAddress(address);
    };

    /*useEffect(() => {
        console.log('results')
        console.log(results)
    }, [results]);*/


    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };
    const handleContract = (event) => {
        const address = event.target.value;
        setContractAddress(address);
        if (address !== '') {
            if (!isValidAddress(address)) {
                setAddressError("Invalid contract address.");
            } else {
                setAddressError("");
            }
        } else {
            setAddressError("");
        }
    };

    const handleTxHash = (event) => {
        const tx = event.target.value;
        setTxHash(tx);
    }


    const handleDateChange = (event) => {
        setRelevantDate(event.target.value);
    };

    useEffect(() => {
        api.get('/chains', {params: {module: 'contracts'}})
            .then(response => {
                setChainList(response.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    const searchForContract = async () => {
        if (!contractAddress) {
            setErrorMessage('Please enter a contract address.');
            return;
        }
        if (!isValidAddress(contractAddress)) {
            setAddressError('Invalid contract address.');
            return;
        }
        if (selectedOption === '') {
            setErrorMessage('Please select a chain.');
            return;
        }
        if (relevantDate === '') {
            setErrorMessage('Please select a date.');
            return;
        }
        setErrorMessage('')
        setIsLoading(true)
        setProgress(0); // Reset the progress to 0 when starting a new search

        const interval = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 1));
        }, 400); // I

        const formattedDate = new Date(relevantDate);
        const unixTimestamp = Math.floor(formattedDate.getTime() / 1000);
        // Perform your async action here
        try {
            /* const parameters = {
                 contract: '0x012312312412412',
                 out_symbol: 'EUR',
             };
            */
            const parameters = {
                contract: contractAddress,
                out_symbol: outSymbol,
                time: unixTimestamp,
                chain: selectedOption
            };
            if (txHash !== '') {
                parameters.tx_hash = txHash;
            }
            const results = await api.get('convert', {params: parameters});
            setResults(results.data)
            setIsLoading(false)
            clearInterval(interval);

        } catch (error) {
            clearInterval(interval);
            /*setServerResponse('Server Error. Please try again.');*/
            setServerResponse(error.response.data.error)
            setIsLoading(false)
        }
        setSearchExecuted(true);
    };

    function calculateStats(values, inSymbol) {
        const valueKey = inSymbol === 'EUR' ? 'value(EUR,1)' : 'value(USD,1)';
        const valueArray = values.map(item => item[valueKey]);
        const median = valueArray[Math.floor(valueArray.length / 2)];
        const mean = valueArray.reduce((acc, val) => acc + val, 0) / valueArray.length;
        const min = Math.min(...valueArray);
        const max = Math.max(...valueArray);
        return {median, mean, min, max};
    }

    const stats = calculateStats(results, outSymbol);

    const accordionProps = {
        sx: {
            pointerEvents: "none"
        },
        expandIcon: (
            <ExpandMoreIcon
                sx={{
                    pointerEvents: "auto"
                }}
            />
        )
    };

    const StatsCard = ({label, text, icon, onCopy, factor = 1}) => {
        const [isCopied, setIsCopied] = useState(false);
        const handleCopy = async () => {
            try {

                await navigator.clipboard.writeText(text);
                setIsCopied(true);
                setTimeout(() => setIsCopied(false), 2000); // Reset the copied state after 2 seconds
            } catch (error) {
                console.error('Copy failed: ', error);
            }
        };


        return (
            <CardContent style={{backgroundColor: 'transparent'}}>
                <Box
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                    style={{backgroundColor: 'transparent'}}
                >
                    <Box
                        display='flex'
                        alignItems='center'
                        justifyContent='space-between'
                        mb={1} // Apply scaling factor to margin
                    >
                        <Box display='flex' alignItems='center' style={{backgroundColor: 'transparent'}}>
                            {icon}
                            <Typography
                                sx={{fontSize: 16 * factor, marginRight: 1, marginLeft: 1, fontWeight: 'bold'}}
                            >
                                {label}
                            </Typography>
                            <Tooltip title="Copy">
                                <IconButton onClick={handleCopy}>

                                    <FileCopy
                                        style={{
                                            color: isCopied ? 'green' : 'white',
                                            fontSize: 16 * factor,
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                    <Box alignItems='center'>
                        <Typography variant='h5'
                                    style={{marginLeft: 5 * factor, fontWeight: 'bold', fontSize: 16 * factor}}>
                            {text}
                        </Typography>
                    </Box>
                </Box>
                {/* Show Alert when copied */}
                {isCopied && (
                    <div style={{
                        position: 'fixed',
                        top: 0,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        zIndex: 1000,
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '5px',
                    }}>
                        <Alert severity="success" sx={{width: '100%', justifyContent: 'center'}}>
                            <AlertTitle>Copied to clipboard</AlertTitle>
                        </Alert>
                    </div>
                )}
            </CardContent>);
    };

    return (
        <>
            <Grid container spacing={2} className='clContainer'>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item>
                        <IconButton className='clButton'>
                            <SearchOutlined style={{color: 'white', fontWeight: 'bold'}}/>
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <h1 style={{margin: '10px 5px'}}>Contract Search</h1>
                    </Grid>
                    <Grid item xs={12}>
                    </Grid>
                </Grid>
                <Grid item xs={10} sm={10}>
                    <TextField
                        className='clTextField'
                        label="Contract Address"
                        variant="outlined"
                        fullWidth
                        value={contractAddress}
                        onChange={handleContract}
                        error={addressError !== ''}
                        helperText={addressError}

                    />
                </Grid>
                <Grid item xs={10} sm={10}>
                    <TextField
                        className='clTextField'
                        label="Transaction Hash (optional)"
                        variant="outlined"
                        value={txHash}
                        onChange={handleTxHash}
                        error={addressError !== ''} // Set error state based on errorMessage
                        helperText={addressError} // Display the error message
                    />
                </Grid>

                <Grid item xs={3} sm={3}>
                    <div className="dropdownContainer">
                        <FormControl
                            variant="outlined"
                            fullWidth
                            className="clFormControl"
                        >
                            <InputLabel style={{color: 'white'}}>Chain</InputLabel>
                            <Select
                                required
                                value={selectedOption}
                                onChange={handleOptionChange}
                                label="Chain"
                                inputProps={{
                                    MenuProps: {
                                        MenuListProps: {
                                            sx: {
                                                backgroundColor: '#333333',
                                                color: 'white'
                                            }
                                        }
                                    }
                                }}
                            >
                                {chainList.map((option, index) => (
                                    <MenuItem key={index} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </Grid>
                <Grid item xs={3} sm={3}>
                    <TextField
                        className="white-icon-textfield clFormControl"
                        label="Date"
                        type="date"
                        variant="outlined"
                        value={relevantDate}
                        onChange={handleDateChange}
                        InputLabelProps={{
                            shrink: true,
                            style: {color: 'white'}, // Apply white color to input label
                        }}
                        InputProps={{
                            style: {color: 'white'}, // Apply white color to input text
                        }}
                        style={{width: '100%', textAlign: 'center'}}
                    />

                </Grid>
                <Grid item xs={2} sm={2}>
                    <FormControl
                        variant="outlined"
                        fullWidth
                        className="clFormControl"
                    >
                        <InputLabel style={{color: 'white'}}>Currency</InputLabel>
                        <Select
                            value={outSymbol}

                            variant="outlined"
                            onChange={(event) => setOutSymbol(event.target.value)}
                            label="Currency"
                            style={{color: 'white'}}
                            sx={{
                                width: '100%',
                                textAlign: 'left' // Set text alignment to left
                            }}
                        >
                            <MenuItem value="EUR">EUR</MenuItem>
                            <MenuItem value="USD">USD</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2} sm={2}>
                    <Button
                        onClick={searchForContract}
                        style={{width: '100%', textAlign: 'center', color: 'white', fontWeight: 'bold', height: '100%'}}
                        className='clButton'

                    >
                        Search
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    <Typography style={{color: 'red', textAlign: 'center'}}
                                variant='body2'>{errorMessage}</Typography>
                </Grid>
            </Grid>


            {
                isLoading ? (
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '50px'
                        }}>
                            <Grid container space={2} style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                                <Grid item xs={5}>
                                    <Typography variant="body1" color="white">
                                        {progress}% Complete<br/>
                                    </Typography>
                                    <LinearProgress variant="determinate" value={progress} style={{margin: '10px'}}/>
                                    <Typography variant="body1" color="white">
                                        Gathering data ...
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                    ) :
                    results.length > 0 ? (
                        <>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: '50px'
                            }}>
                                <Grid container space={2} style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: '50px'
                                }}>
                                    {/*<Grid item xs={12} style={{marginBottom: '35px'}}>
                                        <Typography variant='h5'>
                                            Result
                                        </Typography>
                                        <Grid item xs={12}></Grid>
                                    </Grid>*/}

                                    <Grid item xs={10} sm={10}>
                                        <Box display="flex" alignItems="center" width="100%"
                                             style={{border: '1px solid gray'}}>
                                            <Box flexGrow={1} display="flex" alignItems="center">
                                                <StatsCard
                                                    icon={<MonetizationOn/>}
                                                    label="Token"
                                                    text={results[0]['name']}
                                                    size={12}
                                                />
                                            </Box>
                                            <Card style={{backgroundColor: 'transparent', border: 'None'}}>

                                                <Box display="flex" justifyContent="space-between"
                                                     alignItems="center">
                                                    <StatsCard
                                                        icon={<DateRange/>}
                                                        label="Date"
                                                        text={relevantDate}
                                                        size={12}
                                                    />

                                                    <StatsCard
                                                        icon={<EqualizerRounded/>}
                                                        label="Mean"
                                                        text={`${stats.mean.toFixed(2)} ${outSymbol}`}
                                                        size={12}
                                                    />
                                                    <StatsCard
                                                        icon={<TrendingFlat/>}
                                                        label="Median"
                                                        text={`${stats.median.toFixed(2)} ${outSymbol}`}
                                                        size={12}
                                                        factor={0.7}
                                                    />
                                                    <StatsCard
                                                        icon={<ExpandMoreIcon/>}
                                                        label="Min"
                                                        text={`${stats.min.toFixed(2)} ${outSymbol}`}
                                                        size={12}
                                                        factor={0.7}
                                                    />
                                                    <StatsCard
                                                        icon={<ExpandLess/>}
                                                        label="Max"
                                                        text={`${stats.max.toFixed(2)} ${outSymbol}`}
                                                        size={12}
                                                        factor={0.7}
                                                    />
                                                </Box>
                                            </Card>

                                        </Box>
                                        <Accordion
                                            sx={{
                                                backgroundColor: '#272c35',
                                                color: 'white',
                                                border: '1px solid gray',
                                                userSelect: 'text',
                                            }}
                                            style={{userSelect: 'text'}}
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon style={{color: 'white'}}/>}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                Details
                                            </AccordionSummary>
                                            <AccordionDetails sx={{backgroundColor: '#272c35', color: 'white'}}>
                                                <TableContainer component={Paper}
                                                                sx={{
                                                                    backgroundColor: '#272c35',
                                                                    color: 'white'
                                                                }}>
                                                    <Table aria-label="simple table">
                                                        <TableHead>
                                                            <TableRow>
                                                                {/*<TableCell style={{color: 'white'}}>Coin In</TableCell>*/}
                                                                <TableCell style={{color: 'white'}}>Coin
                                                                    In</TableCell>
                                                                <TableCell
                                                                    style={{color: 'white'}}>Transaction</TableCell>
                                                                <TableCell style={{color: 'white'}}>Coin Out</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody style={{color: 'white'}}>
                                                            {results.map((item, index) => (
                                                                <TableRow key={index}
                                                                          style={{color: 'white'}}>
                                                                    {/* ... Other table cells */}
                                                                    <TableCell
                                                                        style={{color: 'white'}}>{item['coin_in']}</TableCell>
                                                                    <TableCell
                                                                        style={{color: 'white'}}>{item['tx']}</TableCell>
                                                                    <TableCell
                                                                        style={{color: 'white'}}>{item['coin_out']}</TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                </Grid>
                            </div>
                        </>) : searchExecuted ? (
                        // No results found or search not performed
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '50px'
                        }}>
                            <Grid container space={2} style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: '50px'
                            }}>
                                <Grid item xs={12}>
                                    {serverResponse ? (
                                        <>
                                            <ErrorOutlineOutlined style={{fontSize: 60}}/>
                                            <Typography variant='h5'>
                                                Oops! Something went wrong!
                                            </Typography>
                                            <Typography variant='caption' style={{color: 'orange'}}>
                                                {serverResponse}
                                            </Typography><br/>
                                            <Typography variant='body'>
                                                Check input parameter.
                                            </Typography>
                                        </>

                                    ) : (
                                        <>
                                            <NotInterested style={{fontSize: 60}}/>
                                            <Typography variant='h6'>
                                                No results found
                                            </Typography>
                                        </>
                                    )}

                                </Grid>
                            </Grid>
                        </div>
                    ) : null
            }
        </>
    );
}
