import * as React from 'react';
import Container from "react-bootstrap/Container";

export default function SCA() {
    return (
        <Container>
            <p>Smart Contract Anaylsis</p>
        </Container>
    );
}
