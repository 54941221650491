import {useState} from 'react';
import axios from "axios";

const api = axios.create({
    baseURL: process.env.REACT_APP_CL_SERVICES_BASE_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

export default function useAccessToken() {
    const getAccessToken = () => {
        const accessToken = localStorage.getItem('accessToken');
        return accessToken
    };

    const [accessToken, setAccessToken] = useState(getAccessToken());

    const saveAccessToken = accessToken => {
        localStorage.setItem('accessToken', accessToken);
        api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
        setAccessToken(accessToken);
    };

    return {
        setAccessToken: saveAccessToken,
        accessToken
    }
}